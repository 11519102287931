import React from "react"
import { graphql, Link } from "gatsby"

import styled from 'styled-components';
import Layout from "../../components/layouts/courseLandingLayout"
import Navbar from '../../components/navbars/courseLandingNavbar';
import SearchEngine from "../../components/SearchEngine"
import { Container, Col, Row } from 'react-bootstrap';

const HeroContainer = styled(Container)`
  margin-top: 250px;

  h1 {
    text-align: center;
  }
`


const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Navbar location={location}/>
      <HeroContainer>
        <SearchEngine title="Parsity Hacker League" />
        <h1>Coming Soon!</h1>
      </HeroContainer>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
