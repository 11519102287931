import styled from 'styled-components';
import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';

const PSButtom = styled.div`
  cursor: pointer;
  background: var(--white);
  border: 2px solid #000;
  padding: 15px 20px;
  display: inline-block;
  text-align: center;
  color: #000;
  text-decoration: none;
  box-shadow: #000 -10px 8px 0px;
  letter-spacing: 2px;
  font-size: 20px;
  min-width: 150px;
  text-align: center;
  font-family: 'roboto-mono';
  margin-top: 10px;
  &:hover {
    background: var(--yellow);
    box-shadow: none;
    color: var(--black);
  }
`

const Button = ({ handleCTAClick, text, href, classToAdd, children }) => {
  const handleClick = () => {
    if (handleCTAClick) {
      handleCTAClick();
    }
  }

  return (
    <PSButtom target="_blank" href={href || '#'} className={'ps-main-btn ' + (classToAdd ? classToAdd : null)} 
      onClick={(e) => handleClick(e)}>{children} {text}
    </PSButtom>
  )
}

export default Button;

